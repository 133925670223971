// @font-face {
//   font-family: 'Angilla Tattoo';
//   src: local('Angilla Tattoo'), url(http://localhost:3000/fonts/angilla-tattoo.ttf) format('truetype');
// }

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

* {
  font-family: 'Poppins', sans-serif !important;
}

.jss4 {
  width: '100% !important';
}

#TextField {
  width: auto;
  height: auto;
}

// * Theme Style Start
$ThemeColor: #1f1f1f;

/* width */
::-webkit-scrollbar {
  width: 8px;
  border-radius: 10px;
  background-color: red;
}

/* Track */
::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: #d9d9d9;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  // box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #dadce0;
}

body {
  margin: 0;
  // font-family: SharpSans, Helvetica, Arial, "Trebuchet MS", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
// }

ul {
  padding: 0px;
  margin: 0px;
  list-style: none;
}

.d-none {
  display: none !important;
}

.sweet-loading {
  text-align: center;
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -40%);
  z-index: 11;
}

.color-loader {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 11;
}

.MuiFormLabel-root.Mui-focused {
  color: $ThemeColor !important;
}

.MuiOutlinedInput-multiline {
  padding: 12px 10px !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: $ThemeColor !important;
}

.MuiGrid-spacing-xs-2,
.MuiGrid-spacing-xs-4 {
  width: 100% !important;
  margin: 0px !important;
}

.MuiFormHelperText-root {
  margin-bottom: 0px !important;
}

@media (min-width: 1280px) {
  .MuiContainer-maxWidthLg {
    max-width: 1200px !important;
  }
}

@media (min-width: 600px) {
  .MuiTab-root {
    min-width: 150px !important;
  }
}

.imageActive {
  border: 1px solid #000 !important;
}

// * Theme Style End

// * DesignTool
#DesignTool {
  .header {
    .logo {
      width: 200px;
    }

    .talk,
    .chat {
      border-left: 1px solid #d5d5d5;

      .icon-wrapper {
        float: left;
        width: 20%;
        text-align: right;
      }

      .text-wrapper {
        float: right;
        width: 80%;

        p {
          font-size: 12px;
          margin: 3px 0px;
        }

        a {
          font-size: 16px;
          font-weight: 400;
          color: $ThemeColor;
          text-decoration: none;

          &:hover {
            opacity: 0.7;
            transition: 0.3s ease-in;
          }
        }
      }
    }

    .cart-wrapper,
    .account-wrapper {
      border-left: 1px solid #d5d5d5;

      .cart,
      .account {
        padding: 6px 0px;

        a {
          color: #000;
          margin: auto;

          &:hover {
            text-decoration: none;
          }
        }

        .icon {
          vertical-align: middle;
          color: rgba($color: #000000, $alpha: 0.7);
          font-size: 25px;
          width: 25px;
          padding: 2px;
        }

        span {
          font-size: 15px;
        }
      }
    }

    .icon {
      width: 35px;
      padding: 5px;
      font-size: 30px;
      color: $ThemeColor;
      vertical-align: bottom;
    }
  }

  .design-section {
    background-color: #d8d8d8;
    padding: 0px 15px;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: calc(100vh - 120px);

    .tool-sidebar {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      width: 40%;
      background-color: #fff;
      padding: 20px 10px;
      margin: 20px 0px;
      border-radius: 10px;

      .MuiTabs-vertical {
        border-right: 2px solid #d5d5d5;
      }

      h1 {
        margin-bottom: 20px;
        text-transform: capitalize;
        font-weight: 700;
      }

      .wrapper {
        position: relative;
        height: 100%;
        padding: 0px;
        overflow-y: auto;
        overflow-x: hidden;
      }

      .header-title {
        position: sticky;
        width: 100%;
        height: 30px;
        top: 0px;
        z-index: 2;
        margin-bottom: 10px;

        h1 {
          margin-bottom: 0px;
          background-color: #fff;
          line-height: 2;
        }
      }

      .close-btn {
        position: absolute;
        right: 5px;
        top: 0px;
        padding: 5px !important;
      }

      .back-btn {
        position: absolute;
        left: 5px;
        top: 0px;
        padding: 5px 5px 5px 10px !important;
      }

      .lock-btn,
      .lockOpen-btn {
        padding: 0px;
        margin-right: 5px;
        border-radius: 5px;
      }

      .edit-tool-content {
        // padding: 0px !important;

        .center-btn {
          border: 1px solid #000;
          padding: 3px 4px;

          .MuiButton-label {
            display: block !important;
            line-height: normal !important;
          }

          .center-icon {
            transform: rotate(90deg);
          }
        }

        .single-color {
          .MuiFormControlLabel-root {
            width: 100% !important;
            margin: 0px !important;
            opacity: 0.8;

            &:hover {
              opacity: 1;
              transition: 0.3s ease-in;
            }
          }

          .MuiTypography-body1 {
            flex: auto !important;
          }

          .MuiSwitch-colorSecondary.Mui-checked {
            color: $ThemeColor;

            &:hover {
              background-color: rgba($color: $ThemeColor, $alpha: 0.04);
            }
          }

          .MuiSwitch-colorSecondary.Mui-checked+.MuiSwitch-track {
            background-color: $ThemeColor;
          }
        }

        .change-art,
        .change-font,
        .change-color,
        .rotation {
          align-items: center;
          padding: 8px;

          &:hover {
            cursor: pointer !important;

            h6 {
              opacity: 1;
              transition: 0.3s ease-in;
            }
          }

          .MuiSvgIcon-root {
            padding: 7px;
          }

          h6 {
            flex: auto;
            opacity: 0.8;
          }

          p {
            color: #1f51cf;
          }
        }

        .change-color {
          .MuiSvgIcon-root {
            margin-top: -2px;
            vertical-align: sub;
          }

          .image-color,
          .text-color {
            width: 20px !important;
            height: 20px !important;
            display: inline-block;
            border-radius: 5px;
            box-shadow: 0px 1px 2px 0px rgba($color: #0000, $alpha: 0.8);
            margin: 0px 2px !important;
            vertical-align: super !important;
          }

          .text-color {
            background-color: #262626;
          }

          .MuiCheckbox-colorSecondary.Mui-checked {
            color: #000 !important;
          }

          .MuiCheckbox-colorSecondary:hover {
            background-color: rgba($color: $ThemeColor, $alpha: 0.04) !important;
          }

          .MuiCheckbox-root {
            padding: 0px !important;
          }
        }

        .image-color-wrap {
          width: 76%;
          float: left;
          padding-top: 7px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }

        .rotation {
          .MuiGrid-item {
            &:nth-child(1) {
              padding: 8px 0px;
            }
          }
        }

        .art-size {
          align-items: center;
          padding: 8px;

          // &:hover {
          //   h6 {
          //     opacity: 1;
          //     transition: 0.3s ease-in;
          //   }
          // }

          h6 {
            flex: auto;
            opacity: 0.8;
          }

          p {
            margin: 0px;
            line-height: 1;
            font-size: 12px;
            letter-spacing: 1px;
          }

          .MuiOutlinedInput-input {
            padding: 5px 10px;
            text-align: center;
          }

          .MuiGrid-item {
            padding: 8px 0px;
          }

          .MuiFormControl-root {
            width: 60px;
          }

          .MuiTypography-body2 {
            margin: 0px 3px;
            font-weight: 500;
            color: #444;
            align-self: center;
          }

          .MuiTypography-caption {
            margin: 0px 8px;
            font-weight: 600;
            font-size: 15px;
            color: #444;
            align-self: center;
          }
        }

        .text-size {
          align-items: center;
          padding: 8px;

          h6 {
            flex: auto;
            opacity: 0.8;
          }


          .MuiOutlinedInput-input {
            padding: 5px 10px;
            text-align: center;
          }

          .MuiFormControl-root {
            width: 80px;
          }

          .MuiGrid-item {
            padding: 4px 0px;
          }
        }

        .reset-save {

          .reset-btn,
          .save-btn {
            width: 150px;
            font-size: 14px;
            margin: 20px 0px;
            padding: 12px 0px;
            text-transform: capitalize;

            &:hover {
              background-color: transparent !important;
              color: #333;
              border: 1px solid #333;
              transition: 0.3s ease-in;
            }
          }
        }

        .MuiButtonGroup-root {
          button {
            border: 1px solid #000;
          }
        }

        .MuiButton-root {
          min-width: 55px !important;
        }
      }

      .color-box {
        position: relative;
        display: inline-block;
        margin-right: 35px;
        margin-bottom: 30px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;

          &:checked {
            ~.checkmark {
              &::after {
                display: block;
              }
            }
          }
        }

        .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          height: 25px;
          width: 25px;
          box-shadow: 0px 1px 2px 0px rgba($color: #0000, $alpha: 0.8);
          border-radius: 5px;

          &::after {
            content: "";
            position: absolute;
            display: none;
            left: 0px;
            top: 0px;
            width: 100%;
            height: 100%;
            background-color: transparent;
            background-image: url(./assets/images/tool-icons/tick.png);
            background-position: center center;
            background-size: 100%;
            background-repeat: no-repeat;
          }
        }
      }

      .change-color-panel {
        padding: 8px;

        &:hover {
          cursor: inherit;
        }

        .image-color {
          width: 25px;
          height: 25px;
          display: inline-block;
          border-radius: 5px;
          box-shadow: 0px 1px 2px 0px rgba($color: #0000, $alpha: 0.8);
          margin: 0px 5px;
          vertical-align: inherit;

          &:hover {
            cursor: pointer;
          }
        }

        h6 {
          flex: auto;
        }
      }

      .MuiTabPanel-root {
        width: 100%;
        padding: 10px 10px !important;
        position: relative;
      }

      .MuiFormControl-root {
        width: 100%;
        max-height: 120px;
      }

      .MuiFormLabel-root {
        line-height: 0.5 !important;
      }

      .MuiOutlinedInput-inputMultiline {
        text-align: center;
        min-height: 20px;
        max-height: 95px;
        overflow-y: auto !important;
      }

      .MuiButton-contained {
        background-color: rgba($color: $ThemeColor, $alpha: 0.8) !important;
        color: #fff;
        margin: 10px 0px;

        &:hover {
          background-color: rgba($color: $ThemeColor, $alpha: 1) !important;
          transition: 0.3s ease-in;
        }
      }

      .toolbar-tab {
        img {
          width: auto;
          height: 35px;
          display: block;
          margin: 0px auto;
        }

        .MuiTabs-fixed {
          overflow-y: auto !important;
        }

        .MuiTabs-indicator {
          background-color: $ThemeColor !important;
          width: 2px;
        }

        .MuiTab-wrapper {
          font-size: 10px;
          font-weight: 700;
          width: 80px !important;
          display: block !important;
          background-color: #d8d8d8;
          border-radius: 6px;
          padding: 8px 5px;
        }

        .MuiTab-textColorInherit.Mui-selected {
          color: $ThemeColor;

          .MuiTab-wrapper {
            background-color: #fff;
          }
        }

        .MuiTab-root {
          padding: 0px !important;
          text-transform: capitalize !important;
        }

        .MuiButtonBase-root {
          display: block;
          margin: 5px 0px !important;
        }

        .MuiTouchRipple-root {
          width: 90px;
        }
      }

      .main-panel {
        nav {
          height: 90%;
          padding: 0px;
          overflow-y: auto !important;
        }

        img {
          width: 110px;
        }

        .start-over {
          margin: 20px 0px;
          text-align: center;
          color: #1f51cf;
          cursor: pointer;
        }
      }

      .text-panel {
        .font-panel {
          li {
            font-size: 22px;
            text-align: center;
            margin: 5px 0px;
            cursor: pointer;

            &:hover {
              background-color: rgba($color: #000000, $alpha: 0.04);
            }
          }
        }
      }

      .art-panel {
        .categories-tab {
          border: none;

          img {
            width: 30px !important;
            height: 30px !important;
            border-radius: 50% !important;
            margin-right: 10px;
            margin-bottom: 0px !important;
            vertical-align: bottom;
          }

          .MuiTabs-indicator {
            display: none !important;
          }

          .MuiTab-wrapper {
            text-align: left !important;
            display: block !important;
          }

          .MuiTab-root {
            max-width: 95% !important;
            min-width: 90% !important;
            background-color: rgba($color: #cccfd6, $alpha: 0.2);
            min-height: 50px;
            opacity: 1 !important;
            margin: 10px auto 4px;
            border: 1px solid #cccfd6;
            padding: 5px 10px !important;
            transition: 0.3s ease-in;
            border-radius: 4px;

            &:hover {
              -webkit-transform: scale(1.05);
              transform: scale(1.05);
              box-shadow: 0 2px 8px 0 rgba($color: #cccfd6, $alpha: 50%);
              transition: 0.3s ease-in;
            }
          }
        }

        .subcategories-tab {
          border: none;

          .MuiTabs-indicator {
            display: none !important;
          }

          .MuiTab-wrapper {
            text-align: center !important;
            display: block !important;
          }

          .MuiTab-root {
            max-width: 98% !important;
            opacity: 1 !important;
            margin-top: 10px;

            &:hover {
              background-color: rgba($color: #000000, $alpha: 0.04);
            }
          }
        }

        .artwork-tab {
          border: none;

          img {
            width: 90px;
            height: 90px;
            padding: 5px !important;
            border: 1px solid #fff;
            cursor: pointer;

            &:hover {
              border: 1px solid $ThemeColor;
              border-radius: 5px;
            }
          }

          .MuiTabs-indicator {
            display: none !important;
          }

          .MuiTouchRipple-root {
            display: none;
          }

          .MuiTab-wrapper {
            text-align: center !important;
            display: block !important;
          }

          .MuiTab-root {
            max-width: 95% !important;
            min-width: 100% !important;
            opacity: 1 !important;
            margin-top: 10px;
            padding: 0px !important;
          }
        }

        .categories-panel,
        .subcategories-panel,
        .edit-tool-content {
          width: 100%;
          padding: 0px 0px !important;
          position: absolute !important;
          left: 0px;
          top: 0px;
          background-color: #fff;
        }
      }

      .upload-panel {
        input[type="file"] {
          position: absolute;
          left: 0px;
          top: 0px;
          display: block;
          opacity: 0;
          height: 100%;
          width: 100%;
          font-size: 0;
          cursor: pointer !important;
        }

        .upload {
          border: 1px dashed #d8d8d8;
          padding: 30px 10px;
          position: relative;

          img {
            width: 70px;
            margin: auto;
          }

          p {
            text-align: center;

            span {
              color: #1f51cf;
            }
          }
        }
      }

      .color-panel {
        .selected-color-box {
          width: 25px;
          height: 25px;
          border-radius: 5px;
          box-shadow: 0px 1px 2px 0px rgba($color: #0000, $alpha: 0.8);
          display: inline-block;
          vertical-align: sub;
          margin: 0px 5px;
        }

        p {
          margin: 10px 0px 5px;
        }
      }

      .number-panel {
        h6 {
          text-align: center;
          margin: 10px 0px;
        }
      }
    }

    .canvas-image-wrapper {
      width: 55%;
      background-color: transparent;
      margin: 0px;
      position: relative;
      text-align: center;
      overflow: hidden;

      .front-canvas,
      .back-canvas {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;

        img {
          width: auto;
          height: 100%;
          margin: auto;
        }

        .product-canvas-mask {
          position: absolute;
          left: 50%;
          top: 0px;
          width: auto;
          height: 100%;
          transform: translateX(-50%);

          &:nth-child(1) {
            z-index: 1;
          }
        }
      }

      .print-area-wrapper {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;

        .print-area {
          position: absolute;
          top: 57px;
          border: 1px dashed rgba($color: #000000, $alpha: 0);
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          transition: 0.2s;

          &:hover {
            border: 1px dashed rgba($color: #000000, $alpha: 1);
            transition: 0.2s;
          }

          .print-box {
            position: absolute;
            // z-index: 4;
            user-select: none;
            cursor: grab;
            // border: 1px solid rgba($color: #000000, $alpha: 0);

            // &:hover {
            //   border: 1px dashed #000;
            // }

            .delete-btn {
              position: absolute;
              left: -21px;
              top: -21px;
              text-align: center;
              background-color: #fff;
              color: #ee3524;

              .MuiSvgIcon-root {
                font-size: 17px;
                width: 17px;
                height: 17px;
              }
            }

            .react-resizable-handle {
              position: absolute;
              right: -21px;
              bottom: -21px;
              padding: 5px !important;
              border-radius: 50%;
              background-color: #fff !important;
              cursor: se-resize;
              width: 25px;
              height: 25px;
              background-image: url(assets/images/tool-icons/se-resize.png) !important;
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center;
            }

            .disabled {
              display: none !important;
            }

            .flip-rotate {
              width: 100%;
              height: 100%;
              border: 1px solid rgba($color: #000, $alpha: 0);

              &:hover {
                border: 1px dashed #000;
              }
            }

            img {
              height: 100%;
              width: 100%;
              pointer-events: none;
            }
          }
        }
      }
    }

    .front-back-thumbnail-wrapper {
      position: absolute;
      right: 10px;
      top: 8px;
      width: 70px;
      background-color: transparent;
      padding: 0px;
      margin: 15px 0px;

      .thumbnail {
        display: block;
        margin: 0px;
        background-color: #efefef;
        // padding: 20px 10px 10px;
        padding: 5px;
        border-radius: 0px;

        p {
          margin: 0px;
          margin-top: 5px;
          font-size: 12px;
          text-align: center;
        }
      }

      .zoom-thumbnail {
        width: 70px;
        background-color: #fff;
        padding: 5px;
        margin: 15px 0px;

        .MuiSvgIcon-root {
          font-size: 30px;
        }
      }
    }

    .undo-redo-thumbnail-wrapper {
      position: absolute;
      left: 42.5%;
      top: 8px;
      width: 70px;
      background-color: transparent;
      padding: 0px;
      margin: 15px 0px;

      .thumbnail {
        display: block;
        margin: 0px;
        margin-bottom: 5px;
        background-color: #efefef;
        padding: 10px 5px 5px;
        border-radius: 5px;

        p {
          margin: 0px;
          font-size: 12px;
          text-align: center;
        }
      }
    }
  }

  .product-tray {
    padding: 10px 0px;

    .MuiButton-root {
      padding: 10px 20px !important;
      color: $ThemeColor;
      border: 2px solid $ThemeColor !important;

      &:hover {
        color: #fff;
        border: none;
        background-color: $ThemeColor;
      }
    }
  }
}

.responsive_toolBar {
  display: none;
}

// Responsive Design Tool
@media screen and (max-width:768px) {
  .responsive_toolBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #e3e1e1;
    margin-top: 12px;
  }

  .responsive_toolBar .MuiTypography-body1 {
    font-size: 0.694rem !important;
  }

  .tool-sidebar {
    display: none !important;
  }

  .canvas-image-wrapper {
    width: 100% !important;
  }

  .undo-redo-thumbnail-wrapper {
    // display: none !important;
    left: 0% !important;
  }

  .product-tray {
    display: flex;
    align-items: center;
    justify-content: center;
  }

}


// * DesignTool End

// * Products
#Product-Categories,
#Product-Subcategories,
#Products,
#Product-Detail {
  background-color: #f6f6f6;

  .breadcrumb {
    margin-top: 15px;

    .MuiBreadcrumbs-ol {
      padding-left: 5px;
    }

    li {
      font-size: 16px;
      text-transform: capitalize;

      a {
        text-decoration: none;
        transition: 0.3s ease-in;

        &:hover {
          color: $ThemeColor;
          transition: 0.3s ease-in;
        }
      }

      p {
        color: $ThemeColor;
      }
    }
  }

  .appbar-wrapper {
    margin: 20px 0px;

    .app-bar {
      position: static;
      background-color: #fff;
      border: 1px solid #e8e8e8;
      color: #444;

      h1 {
        flex-grow: 1;
        margin: 0px;
        margin-left: 5px;
        font-weight: 600;
        font-size: 28px;
        text-transform: capitalize;
      }

      .search-bar {
        position: relative;
        border-radius: 5px;
        background-color: rgba($color: #cccfd6, $alpha: 0.8);

        .search-icon {
          position: absolute;
          left: 0px;
          top: 0px;
          padding: 5px 8px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .MuiInputBase-input {
          padding: 8px 8px 8px 40px;
          width: 140px;
          transition: 0.3s ease-in;

          &:focus {
            transition: 0.3s ease-in;
            width: 190px;
          }
        }
      }
    }
  }

  .product {
    margin: 20px 0px;

    .category-nav {
      background-color: #fff;

      .MuiAccordionSummary-content {
        margin: 0px !important;
      }

      .MuiAccordionSummary-root.Mui-expanded {
        min-height: 100% !important;
      }

      .MuiAccordionDetails-root {
        display: block !important;
      }

      h4 {
        font-size: 17px;
        font-weight: 600;
        margin: 0px;
        text-transform: capitalize;
      }

      li {
        list-style: none;
        padding: 0px 0px 5px 10px;

        a {
          color: #444;
          font-size: 15px;
          padding: 5px 0px;
          text-transform: capitalize;

          &:hover {
            color: #1e39d2;
            text-decoration: none;
          }
        }
      }

      .filter {
        li {
          font-weight: 600;
          text-transform: capitalize;
        }

        .MuiCheckbox-root {
          padding: 5px;
          color: $ThemeColor;
        }

        .MuiSvgIcon-root {
          font-size: 30px;
        }

        .MuiTypography-body1 {
          font-weight: 600;
        }

        .color-checkbox {
          position: relative;
          left: 0px;
          top: 0px;
          width: 30px;
          height: 30px;
          display: inline-block;
          margin: 5px;
          cursor: pointer;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;

          input {
            position: absolute;
            left: 0px;
            top: 0px;
            width: 30px;
            height: 30px;
            z-index: 1;
            opacity: 0;
            cursor: pointer;
            margin: 0px;

            &:checked {
              ~.checkmark {
                &::after {
                  display: block !important;
                }
              }
            }
          }

          .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 30px;
            width: 30px;
            box-shadow: 0px 1px 2px 0px rgba($color: #0000, $alpha: 0.8);
            border-radius: 5px;

            &::after {
              content: "";
              position: absolute;
              display: none;
              left: 0px;
              top: 0px;
              width: 100%;
              height: 100%;
              background-color: transparent;
              background-image: url(./assets/images/tool-icons/tick.png);
              background-position: center center;
              background-size: 100%;
              background-repeat: no-repeat;
            }
          }
        }
      }
    }

    .card-wrapper {
      background-color: transparent;
      margin-left: 15px;

      .card {
        width: 100%;
        background-color: #fff;
        text-align: center;
        padding: 10px 0px;
        transition: 0.3s ease-in;

        &:hover {
          -webkit-transform: scale(1.05);
          transform: scale(1.05);
          box-shadow: 0 2px 8px 0 rgba($color: #cccfd6, $alpha: 50%);
          transition: 0.3s ease-in;
        }

        a {
          color: #444;
          text-decoration: none;

          h4 {
            font-weight: 600;
            margin-bottom: 5px;
            text-align: center;
            text-transform: capitalize;
          }
        }
      }
    }
  }

  .product-detail {
    margin-top: 15px;
    padding: 20px 0px;
    background-color: #fff;
    color: #444;

    .description {
      font-size: 16px;
      line-height: 1.75;
      padding: 10px 0px;
      margin-bottom: 10px;
    }

    .designing-btn {
      width: 100%;
      padding: 10px 0px;
      font-size: 16px;
      font-weight: 600;

      &:hover {
        background-color: #1f1f1f;
        color: #fff;
        transition: 0.3s ease-in;
      }
    }
  }

  .overview-information {
    padding: 30px 0px;

    .subtitle {
      font-size: 24px;
      font-weight: 600;
      color: #444;
      border-bottom: 1px solid #444;
      text-transform: capitalize;
      margin-top: 15px;
    }

    ul {
      padding-left: 20px;
      margin-top: 20px;

      li {
        font-size: 15px;
        list-style: disc;
        margin-bottom: 15px;
        color: #444;
      }
    }

    p {
      font-size: 16px;
      margin: 10px 0px;
    }

    .MuiAppBar-root {
      background-color: #000 !important;
      border-start-end-radius: 5px;
      border-start-start-radius: 5px;
      color: #fff;
      box-shadow: 0px 2px 4px -1px rgba($color: #000000, $alpha: 0.2),
        0px 4px 5px 0px rgba($color: #000000, $alpha: 0.4), 0px 1px 10px 0px rgba($color: #000000, $alpha: 0.2);
    }

    .MuiTab-root {
      padding: 15px 25px;
      margin: 0px 5px;
    }

    .MuiButtonBase-root {
      text-transform: capitalize;
      font-weight: 600;
      font-size: 16px;
    }

    .MuiTabs-indicator {
      background-color: #e0e0e0;
    }

    .MuiTabPanel-root {
      background-color: #fff;
      border-end-end-radius: 5px;
      border-end-start-radius: 5px;
      box-shadow: 0px 2px 4px -1px rgba($color: #000000, $alpha: 0.2),
        0px 4px 5px 0px rgba($color: #000000, $alpha: 0.4), 0px 1px 10px 0px rgba($color: #000000, $alpha: 0.2);
    }
  }

  .related-product {
    .MuiCardContent-root {
      padding: 8px;
      text-align: center;
    }
  }
}

// * Products End



// * Product SVG
#product-svg {
  position: absolute;
  left: 0;
  right: 16px;
}

// * cursor pointer
.pointer {
  cursor: pointer;
}

// *invert color
.invert-color {
  filter: invert(1);
}

.color-white {
  background-color: #fff !important;
  border-color: #fff !important;
}

#container {
  display: inline-block;
  border: 1px solid gray;
  padding: 10px;
  overflow: hidden;
  text-align: center;
}

#rotator {
  display: inline-block;
  width: 200px;
  height: auto;
  background-color: rgb(130, 310, 130);
  border: 1px solid blue;
  text-align: center;
  line-height: 500px;

  /* Transition Rotation */
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.myNumberType {

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

iframe {
  pointer-events: none;
}

.swal2-confirm {
  background-color: #d30e07 !important;
}